import LatestCourses from "./LatestCourses";
import "./Ersthelfer.css";
import { useRef } from "react";
import { useState } from "react";
import TerminPopUp from "./TerminPopUp";

export default function Ersthelfer() {
    const coursesRef = useRef(null);
    const [shown, setShown] = useState(false);

    const onClickKurse = () => {
        coursesRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const onClickTermin = (e) => {
        e.preventDefault();
        document.body.style.overflow = "hidden";
        setShown(true);
    };

    const onClickX = () => {
        document.body.style.overflow = "scroll";
        setShown(!shown);
    };

    return (
        <>
            <TerminPopUp shown={shown} onClickX={onClickX} />
            <div className="ersthelfer-fullpage-wrapper">
                <div className="ersthelfer-wrapper">
                    <h1>Jede*r kann MHFA-Ersthelfer*in werden.</h1>

                    <div className="zussamenarbeit-wrapper">
                        <img
                            alt="Zussamenarbeit MHFA"
                            src="https://i.ibb.co/LvjY6xM/MHFA-Partnerschaft-1200x630-800x357.png"
                        ></img>

                        <button
                            className="button-kurse-mhfa"
                            onClick={onClickKurse}
                        >
                            Aktuelle Kurse
                        </button>
                    </div>
                    <h3>
                        Werden Sie zertifizierte MHFA-Ersthelfer*in für
                        psychische Gesundheit in Online- oder Präsenzkursen!
                    </h3>
                    <div className="text-wrapper-ersthelfer">
                        <div>
                            <h3>Das Ziel der MHFA Ersthelfer-Kurse</h3>
                            <p>
                                Erwachsene befähigen, ihren Angehörigen,
                                Kolleg*innen oder Freund*innen Erste Hilfe für
                                psychische Gesundheit zu leisten, indem sie in
                                der Lage sind, Anzeichen von psychischen
                                Störungen zu erkennen, zu verstehen und darauf
                                zu reagieren. Die Kurse bieten darüber hinaus
                                einen sinnvollen Mehrwert für
                                Bildungseinrichtungen, Betriebe, medizinische
                                Erstversorger*innen, sowie für viele andere
                                Organisationen und Unternehmen.
                            </p>
                        </div>
                        <div>
                            <h3>MHFA Ersthelfer</h3>
                            <p>
                                MHFA Ersthelfer wird vom Zentralinstitut für
                                Seelische Gesundheit in Mannheim getragen. Die
                                gemeinnützige Einrichtung etabliert
                                deutschlandweit Erste-Hilfe-Kurse für psychische
                                Gesundheit nach dem lizenzierten Modell von
                                Mental Health First Aid International. Ich biete
                                die Kurse in Kooperation mit dem
                                Zentralinstituts für Seelische Gesundheit an.
                            </p>
                        </div>
                        <div>
                            <h3>Hintergrund</h3>
                            <p>
                                Mehr als 40 Prozent der deutschen Bevölkerung
                                erleben mindestens einmal in ihrem Leben eine
                                behandlungsbedürftige psychische Störung. Obwohl
                                sich viele Betroffene in unserem unmittelbaren
                                Umfeld befinden, löst dieses Thema immer noch
                                Verunsicherung, Vorurteile und Ängste aus, daher
                                sind Prävention und Entstigmatisierung in diesem
                                Bereich bedeutsam.
                            </p>
                        </div>
                        <div>
                            <h3>Ablauf</h3>
                            <p>
                                Der Kurs besteht aus vier dreistündigen Modulen
                                und kann als 2- Tageskurs oder verteilt über
                                vier Tage angeboten werden. Zusätzlich gibt es
                                den MHFA Online-Ersthelfer-Kurs, der aus 6
                                zweistündigen Modulen besteht. Zur Teilnahme
                                sind keine Vorkenntnisse erforderlich. Nach
                                Abschluss des Ersthelfer-Kurses können die
                                Absolvent*innen einen Online-Test absolvieren,
                                um akkreditierte*r MHFA-Ersthelfer*in zu werden.
                            </p>
                        </div>
                        <div>
                            <h3>Inhalt des Programms</h3>
                            <p>
                                Hier setzt das Programm MHFA-(Mental Health
                                First Aid) Ersthelfer an. Der MHFA
                                Ersthelfer-Kurs für psychische Gesundheit wurde
                                nach dem erfolgreichen Leitbild für Erste Hilfe
                                bei körperlichen Erkrankungen entwickelt und
                                bildet Laien in 12 Stunden zu Ersthelfenden für
                                psychische Gesundheit aus. Neben theoretischer
                                Wissensvermittlung werden auch konkrete
                                Erste-Hilfe-Maßnahmen bei sich entwickelnden
                                psychischen Gesundheitsproblemen und bei akuten
                                psychischen Krisen erlernt, und durch praktische
                                Übungen gefestigt. Der MHFA Ersthelfer-Kurs
                                verbessert das Wissen über psychische
                                Gesundheit, vermindert stigmatisierendes
                                Verhalten, steigert das Vertrauen in die eigenen
                                Helferkompetenzen und stärkt die eigene
                                psychische Gesundheit.
                            </p>
                        </div>
                        <div></div>
                        <div className="unternehmen-angebote-information-wrapper">
                            <h1>MHFA für Unternehmen und Organisationen</h1>
                            <h3>
                                Krankmeldungen wegen psychischer
                                Gesundheitsprobleme werden häufiger und länger
                            </h3>
                            <p>
                                Eine{" "}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://www.tk.de/resource/blob/2145756/3005523ae7a54b38cbdd7445021cdb11/stu
                            die--whatsnext-2023-data.pdf"
                                >
                                    Arbeitgeber-Studie
                                </a>
                                &nbsp;des Instituts für Betriebliche
                                Gesundheitsberatung, die gemeinsam mit der
                                Techniker Krankenkasse und dem Personalmagazin,
                                Haufe Gruppe im März 2023 veröffentlicht wurde
                                zeigt, dass psychische Belastungen am
                                Arbeitsplatz immer bedeutender werden. Im
                                letzten Jahr habe der Anteil am
                                Gesamtkrankenstand wegen psychischer
                                Gesundheitsprobleme rund 17,5 Prozent betragen.
                                <br></br>
                                <br></br> Das Thema psychische Gesundheit, nehme
                                an Relevanz deutlich zu. 38,5 Prozent der
                                befragten Geschäftsführenden,
                                Gesundheitsverantwortlichen sowie
                                Personalerinnen und Personaler gaben an, dass
                                psychische Belastungen am Arbeitsplatz wie
                                Burnout, Überforderung und Depressionen bereits
                                jetzt eine eher große bzw. große Bedeutung in
                                ihren Unternehmen hätten. Auf die Frage, welche
                                Bedeutung Burnout und Co. in drei Jahren haben
                                werden, sagen das sogar rund 70 Prozent der
                                Befragten. <br></br> <br></br>
                                &quot;Psychische Erkrankungen am Arbeitsplatz
                                haben die körperlichen Belastungen in ihrer
                                Dringlichkeit in vielen Branchen überholt. Das
                                ist eine große Herausforderung, der sich die
                                Arbeitgeber stellen müssen - gleichzeitig aber
                                auch eine Chance, die Gesundheit der
                                Beschäftigten in Arbeitsprozessen und
                                Unternehmenskultur fest zu verankern.&quot;{" "}
                                <span className="autor-quote">
                                    Karen Walkenhorst, Personalvorständin der TK
                                </span>{" "}
                                <br></br>
                                <br></br>Wir von MHFA wissen, dass die
                                Früherkennung psychischer Gesundheitsprobleme
                                schwere Erkrankungen verhindern kann und es den
                                Genesungsverlauf positiv beeinflusst, wenn
                                Betroffene frühzeitig mit dem Hilfesystem in
                                Kontakt kommen. Zu Ersthelfer*innen ausgebildete
                                Mitarbeiter*innen sind in der Lage, angemessen
                                zu helfen und leisten damit einen wertvollen
                                Beitrag für das Unternehmen und die Gesellschaft
                                insgesamt.
                                <br></br>
                                <br></br>
                                Das MHFA Programm ist Ausdruck einer
                                Unternehmenskultur im Sinne von Nachhaltigkeit
                                und sozialer Verantwortung (CSR). Es steigert
                                intern wie extern die Reputation eines
                                Unternehmens, das sich seiner individuellen und
                                gesellschaftlichen Verantwortung bewusst ist.
                            </p>
                        </div>
                    </div>
                    <div className="unternehmen-angebote-wrapper">
                        <h1>MHFA-Kurse für Unternehmen und Organisationen</h1>
                        <div className="unternehmen-image-text-wrapper">
                            <img
                                src="https://i.ibb.co/9NhK41p/Design-ohne-Titel-2.png"
                                alt="charikatur seelisch krank"
                            />
                            <div>
                                <p>
                                    Haben Sie als Unternehmen oder Organisation
                                    Interesse an einem MHFA Ersthelfer-Kurs für
                                    Ihre Mitarbeitenden?
                                </p>
                                <button onClick={onClickTermin}>
                                    Nehmen Sie Kontakt auf
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={coursesRef}>
                    <LatestCourses showTop={false} />
                </div>
            </div>
        </>
    );
}
