import "./HamburgerMenu.css";

export default function HamburgerMenu() {
    const isOpen = false;

    function handleClick() {
        const menu = document.querySelector(".menu");
        const lines = document.querySelectorAll(".line");
        const bubble = document.querySelector(".talk-bubble");

        menu.classList.toggle("open");

        if (menu.classList.contains("open")) {
            document.body.style.overflow = "hidden";
            bubble.style.display = "none";
            lines[0].style.transform = "rotate(45deg) translate(5px, 6px)";
            lines[1].style.opacity = "0";
            lines[2].style.transform = "rotate(-45deg) translate(5px, -6px)";
        } else {
            document.body.style.overflow = "";
            bubble.style.display = "flex";
            lines[0].style.transform = "";
            lines[1].style.opacity = "";
            lines[2].style.transform = "";
        }
    }
    return (
        <div className="hamburger-menu">
            <div
                className={`menu-button ${isOpen ? "open" : ""}`}
                onClick={handleClick}
            >
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
            <div className={`menu ${isOpen ? "open" : ""}`}>
                <a href="/">Home</a>
                <a href="/beratungspraxis">Beratungspraxis</a>
                <a href="/ersthelferkurse">MHFA-Ersthelferkurse</a>
                <a href="/ichschaffs">Ich Schaff's</a>
                <a href="/uebermich">Über Mich</a>
                <a href="/blog">Blog</a>
                <a href="/kontakt">Kontakt</a>
            </div>
        </div>
    );
}
