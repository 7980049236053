import "./Bottom.css";

export default function Bottom() {
    return (
        <div className="bottom-wrapper">
            <div>
                <a href="/">Impressum</a>
                <a href="/">Datenschutz</a>
                <a href="/">Allgemeine Geschäftsbedingungen</a>
                <a href="/">Jobs</a>
            </div>
            <div>
                <p>Haus Klara Nova</p>
                <p className="copyright-bottom">
                    © 2022 Haus Klara Nova. Alle Rechte vorbehalten. <br></br>
                    Geschützte Unternehmensinformationen von Haus Klara Nova
                </p>
            </div>
        </div>
    );
}
