import "./navbar.css";
import { useHistory } from "react-router";
import { useEffect, useState, useCallback } from "react";
import { client } from "./contentfulSetup";

export default function Navbar() {
    const history = useHistory();
    const [isActive, setIsActive] = useState("HOME");
    const [logo, setLogo] = useState();

    const onClick = (e) => {
        setIsActive(e.target.innerHTML);

        if (e.target.innerHTML === "HOME") history.push("/");
        else if (e.target.innerHTML === "MHFA-ERSTHELFERKURSE")
            history.push("/ersthelferkurse");
        else if (e.target.innerHTML === "KLARA NOVA")
            history.push("/klaranova");
        else if (e.target.innerHTML === "ÜBER MICH") history.push("/uebermich");
        else if (e.target.innerHTML === "ICH SCHAFF'S")
            history.push("/ichschaffs");
        else history.push(e.target.innerHTML.toLowerCase());
    };

    const cleanUpData = useCallback((rawData) => {
        const cleanLogo = rawData.map((image) => {
            const { sys, fields } = image;
            const id = sys.id;
            const imgUrl = fields.logoImage.fields.file.url;
            const cleanObj = { id, imgUrl };
            return cleanObj;
        });

        console.log("cleanLogo", cleanLogo[0]);
        setLogo(cleanLogo[0]);
    }, []);

    const getLogoImage = useCallback(async () => {
        try {
            const response = await client.getEntries({
                content_type: "logo",
            });

            const responseData = response.items;
            if (responseData) {
                cleanUpData(responseData);
            } else {
                setLogo([]);
            }
        } catch (error) {
            console.log(error);
        }
    }, [cleanUpData]);

    useEffect(() => {
        getLogoImage();
    }, [getLogoImage]);

    return (
        <div className="navbar-wrapper">
            {logo && (
                <img className="logo-image" src={logo.imgUrl} alt="logo" />
            )}
            <div className="navbar-buttons-wrapper">
                <span
                    className={isActive === "HOME" ? "active-navbar-span" : ""}
                    onClick={onClick}
                >
                    HOME
                </span>
                <span
                    className={
                        isActive === "BERATUNGSPRAXIS"
                            ? "active-navbar-span"
                            : ""
                    }
                    onClick={onClick}
                >
                    BERATUNGSPRAXIS
                </span>
                <span
                    className={
                        isActive === "MHFA-ERSTHELFERKURSE"
                            ? "active-navbar-span"
                            : ""
                    }
                    onClick={onClick}
                >
                    MHFA-ERSTHELFERKURSE
                </span>
                <span
                    className={
                        isActive === "ICH SCHAFF'S" ? "active-navbar-span" : ""
                    }
                    onClick={onClick}
                >
                    ICH SCHAFF'S
                </span>
                <span
                    className={
                        isActive === "ÜBER MICH" ? "active-navbar-span" : ""
                    }
                    onClick={onClick}
                >
                    ÜBER MICH
                </span>
                <span
                    className={
                        isActive === "KLARA NOVA" ? "active-navbar-span" : ""
                    }
                    onClick={onClick}
                >
                    KLARA NOVA
                </span>
                <span
                    className={isActive === "BLOG" ? "active-navbar-span" : ""}
                    onClick={onClick}
                >
                    BLOG
                </span>
                <span
                    className={
                        isActive === "KONTAKT" ? "active-navbar-span" : ""
                    }
                    onClick={onClick}
                >
                    KONTAKT
                </span>
            </div>
        </div>
    );
}
