import "./BookNowBanner.css";
import { client } from "./contentfulSetup";
import { useState, useCallback, useEffect } from "react";
import TerminPopUp from "./TerminPopUp";

export default function BookNowBanner() {
    const [image, setImage] = useState();
    const [shown, setShown] = useState(false);

    const getImage = useCallback(async () => {
        try {
            const response = await client.getEntries({
                content_type: "bookNowBanner",
            });
            const responseData =
                response.items[0].fields.bookNowBannerImage.fields.file.url;

            if (responseData) {
                setImage(responseData);
            } else {
                setImage([]);
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getImage();
    }, [getImage]);

    const onClickTermin = (e) => {
        e.preventDefault();
        document.body.style.overflow = "hidden";
        setShown(true);
    };

    const onClickX = () => {
        document.body.style.overflow = "scroll";
        setShown(!shown);
    };

    return (
        <>
            <TerminPopUp shown={shown} onClickX={onClickX} />
            <div className="booknowbanner-wrapper">
                <img src={image} alt="banner" />
                <div className="booknowbanner-text-wrapper">
                    <p>Kontakt aufnehmen</p>
                    <p>
                        Für Termine und Anfragen schreiben Sie uns <br></br>
                        gerne eine E-Mail oder rufen Sie uns an
                    </p>
                    <div className="booknowbanner-buttons-wrapper">
                        <button onClick={onClickTermin}>
                            Jetzt E-Mail schreiben
                        </button>
                        <button onClick={onClickTermin}>Anrufen</button>
                    </div>
                </div>
            </div>
        </>
    );
}
