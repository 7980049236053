import "./PraxisHome.css";
import { client } from "./contentfulSetup";
import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router";

export default function PraxisHome() {
    const history = useHistory();
    const [praxisImages, setPraxisImages] = useState([]);

    const getPraxis = useCallback(async () => {
        try {
            const response = await client.getEntries({
                content_type: "praxis",
            });
            const responseData = response.items[0].fields;
            if (responseData) {
                setPraxisImages(responseData.praxisFotos);
            } else {
                setPraxisImages([]);
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getPraxis();
    }, [getPraxis]);

    return (
        <div className="praxis-home-wrapper">
            <div className="praxis-top-wrapper">
                <h3>Beratungspraxis</h3>
                <button onClick={() => history.push("/beratungspraxis")}>
                    Zur Praxis
                </button>
            </div>
            <div className="praxis-bottom-wrapper">
                <div className="praxis-image-wrapper">
                    {praxisImages.map((image, index) => {
                        return (
                            <img
                                key={index}
                                src={image.fields.file.url}
                                alt={image.fields.description}
                            ></img>
                        );
                    })}
                </div>
                <div className="praxis-text-wrapper">
                    <p>
                        Guten Tag, liebe Besucherin, lieber Besucher! <br></br>{" "}
                        <br></br>
                        Ich bin Heike Petereit-Zipfel und heiße Sie herzlich
                        willkommen auf der Homepage von „Klara Nova“. Hier
                        stelle ich Ihnen meine Beratungs- und Therapiearbeit vor
                        und informiere über Kurse, Workshops und Vorträge.{" "}
                        <br></br> <br></br>Schon jetzt laden wir Sie herzlich
                        ein, als Gruppe oder Familie eine entspannte und
                        erholsame Auszeit bei uns zu verbringen, sobald die
                        Renovierung unseres Gästebereichs abgeschlossen sein
                        wird. <br></br> <br></br>Ihre
                        <br></br> <br></br>
                    </p>
                    <p></p>
                    <p></p>
                </div>
                <div className="signature-praxishome">
                    <h3>Heike Petereit-Zipfel</h3>
                </div>
            </div>
        </div>
    );
}
