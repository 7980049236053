import "./LatestCourses.css";
import { client } from "./contentfulSetup";
import { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router";

export default function LatestCourses({ showTop }) {
    const history = useHistory();
    const [events, setEvents] = useState(null);

    const cleanUpData = useCallback((rawData) => {
        const cleanEvents = rawData.map((singleEvent) => {
            const { sys, fields } = singleEvent;
            const { id } = sys;
            const {
                courseDescription,
                courseImage,
                courseName,
                courseLink,
                courseCity,
                courseStreet,
                courseVenue,
            } = fields;
            const imgUrl = courseImage.fields.file.url;
            const datumArr = fields.datumse.datumArr;
            const cleanObj = {
                id,
                imgUrl,
                datumArr,
                courseDescription,
                courseName,
                courseLink,
                courseCity,
                courseStreet,
                courseVenue,
            };
            return cleanObj;
        });

        if (cleanEvents.length) setEvents(cleanEvents);
    }, []);

    const getEvents = useCallback(async () => {
        try {
            const response = await client.getEntries({
                content_type: "courses",
            });
            const responseData = response.items;

            if (responseData) {
                cleanUpData(responseData);
            } else {
                setEvents(null);
            }
        } catch (error) {
            console.log(error);
        }
    }, [cleanUpData]);

    useEffect(() => {
        getEvents();
    }, [getEvents]);

    return (
        <>
            {events && (
                <div className="latest-courses-wrapper">
                    {showTop ? (
                        <div className="latest-courses-text-wrapper">
                            <h3>Demnächst</h3>
                            <button
                                onClick={() => history.push("/ersthelferkurse")}
                            >
                                Mehr Kurse anzeigen
                            </button>
                        </div>
                    ) : (
                        <div className="aktuelle-wrapper">
                            <h3>Termine</h3>
                        </div>
                    )}
                    <div className="latest-courses-container">
                        {showTop
                            ? events.slice(0, 1).map((event, index) => {
                                  return (
                                      <div
                                          className="latest-course-wrapper"
                                          key={index}
                                      >
                                          <img
                                              src={event.imgUrl}
                                              alt={event.eventName}
                                          ></img>
                                          <div className="rightside-wrapper-courses">
                                              <div className="course-name-wrapper">
                                                  <h4>{event.courseName}</h4>
                                                  <div>
                                                      <p className="course-description-text">
                                                          {
                                                              event.courseDescription
                                                          }
                                                      </p>
                                                  </div>
                                                  {event.courseStreet && (
                                                      <div>
                                                          <h4>Wo?</h4>
                                                          <p>
                                                              {
                                                                  event.courseVenue
                                                              }
                                                          </p>
                                                          <p>
                                                              {
                                                                  event.courseStreet
                                                              }
                                                          </p>
                                                          <p>
                                                              {event.courseCity}
                                                          </p>
                                                      </div>
                                                  )}
                                                  <div>
                                                      <h4>Wann?</h4>{" "}
                                                      <div className="dates-course">
                                                          {event.datumArr.map(
                                                              (date, index) => {
                                                                  return (
                                                                      <div
                                                                          key={
                                                                              index
                                                                          }
                                                                          className="date-latestCourse"
                                                                      >
                                                                          <p>
                                                                              Tag
                                                                              &nbsp;
                                                                              {index +
                                                                                  1}
                                                                              :
                                                                              &nbsp;
                                                                              {
                                                                                  date
                                                                              }
                                                                          </p>
                                                                      </div>
                                                                  );
                                                              }
                                                          )}
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="course-button-wrapper">
                                                  <a
                                                      target="_blank"
                                                      rel="noreferrer"
                                                      href={event.courseLink}
                                                  >
                                                      Anmelden
                                                  </a>
                                              </div>
                                          </div>
                                      </div>
                                  );
                              })
                            : events.slice(0, 3).map((event, index) => {
                                  return (
                                      <div
                                          className="latest-course-wrapper"
                                          key={index}
                                      >
                                          <img
                                              src={event.imgUrl}
                                              alt={event.eventName}
                                          ></img>
                                          <div className="rightside-wrapper-courses">
                                              <div className="course-name-wrapper">
                                                  <h4>{event.courseName}</h4>
                                                  <div>
                                                      <p className="course-description-text">
                                                          {
                                                              event.courseDescription
                                                          }
                                                      </p>
                                                  </div>
                                                  {event.courseStreet && (
                                                      <div>
                                                          <h4>Wo?</h4>
                                                          <p>
                                                              {
                                                                  event.courseVenue
                                                              }
                                                          </p>
                                                          <p>
                                                              {
                                                                  event.courseStreet
                                                              }
                                                          </p>
                                                          <p>
                                                              {event.courseCity}
                                                          </p>
                                                      </div>
                                                  )}
                                                  <div>
                                                      <h4>Wann?</h4>{" "}
                                                      <div className="dates-course">
                                                          {event.datumArr.map(
                                                              (date, index) => {
                                                                  return (
                                                                      <div
                                                                          key={
                                                                              index
                                                                          }
                                                                          className="date-latestCourse"
                                                                      >
                                                                          <p>
                                                                              Tag
                                                                              &nbsp;
                                                                              {index +
                                                                                  1}
                                                                              :
                                                                              &nbsp;
                                                                              {
                                                                                  date
                                                                              }
                                                                          </p>
                                                                      </div>
                                                                  );
                                                              }
                                                          )}
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="course-button-wrapper">
                                                  <a
                                                      target="_blank"
                                                      rel="noreferrer"
                                                      href={event.courseLink}
                                                  >
                                                      Anmelden
                                                  </a>
                                              </div>
                                          </div>
                                      </div>
                                  );
                              })}
                    </div>
                </div>
            )}
        </>
    );
}
