import "./Beratungspraxis.css";
import { useCallback, useState, useEffect } from "react";
import { client } from "./contentfulSetup";
import TerminPopUp from "./TerminPopUp";

export default function Beratungspraxis() {
    //const history = useHistory();
    const [beratungsPraxisImages, setBeratungsPraxisImages] = useState(null);
    const [einzelpreis, setEinzelpreis] = useState(null);
    const [gruppenpreis, setGruppenpreis] = useState(null);
    const [shown, setShown] = useState(false);

    const getBeratungspraxis = useCallback(async () => {
        try {
            const response = await client.getEntries({
                content_type: "beratungsPraxis",
            });
            const responseData = response.items[0].fields;
            if (responseData) {
                setBeratungsPraxisImages(responseData.beratungspraxisFotos);
                setEinzelpreis(responseData.einzelberatungPreis);
                setGruppenpreis(responseData.gruppenberatungPreis);
            } else {
                setBeratungsPraxisImages([]);
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getBeratungspraxis();
    }, [getBeratungspraxis]);

    const onClickTermin = (e) => {
        e.preventDefault();
        document.body.style.overflow = "hidden";
        setShown(true);
    };

    const onClickX = () => {
        document.body.style.overflow = "scroll";
        setShown(!shown);
    };

    return (
        <>
            <TerminPopUp shown={shown} onClickX={onClickX} />
            <div className="beratungspraxis-page-wrapper">
                {beratungsPraxisImages && (
                    <div className="beratungspraxis-fotos-wrapper">
                        {beratungsPraxisImages.map((x) => {
                            return (
                                <img
                                    key={x.sys.id}
                                    src={x.fields.file.url}
                                    alt={x.fields.title}
                                />
                            );
                        })}
                    </div>
                )}
                <div className="beratungspraxis-text-wrapper">
                    <h3>Beratungs- / Therapieansätze</h3>

                    <strong>
                        Von der Problemfokussierung zur Lösungsfokussierung
                    </strong>
                    <br></br>
                    <br></br>
                    <p>
                        Ich folge einem lösungsfokussierten und systemischen
                        Beratungs-/Therapieansatz bei der Arbeit mit Familien,
                        Eltern, Kindern und Jugendlichen, als auch in Bildungs-
                        und Betreuungseinrichtungen. Gemeinsam verfolgen wir das
                        Ziel, Ihre persönlichen Stärken zu nutzen,
                        Wechselwirkungen von Verhalten oder auch
                        Nicht-Verhalten, und ungünstige Kommunikationsmuster in
                        Ihrem Umfeld zu ergründen, und für tragfähige Lösungen
                        nutzbar zu machen. Bei meiner Arbeit mit Kindern und
                        Eltern greife ich insbesondere auf den{" "}
                        <a href="/ichschaffs">„ich schaff´s-Ansatz“</a> zurück.
                        Hierbei lernen alle hinzu und gehen erhobenen Hauptes
                        weiter. <br></br>
                        <br></br>
                        Kommen Sie in Ihrem eigenen Tempo mit
                        lösungsfokussierter Kurzzeit-Therapie vom Problem zur
                        Lösung.
                    </p>
                    <br></br>
                    <strong>Psychisch belastete familiäre Situationen</strong>
                    <br></br>
                    <br></br>
                    <p>
                        Die Beratung und Begleitung von Angehörigen und
                        Nahestehenden psychisch schwer belasteter Menschen,
                        entwickelte sich in den vergangenen zehn Jahren zu einem
                        Schwerpunkt meiner Arbeit.
                    </p>
                    <br></br>
                    <p>
                        Es entlastet und stärkt uns, wenn wir als
                        Familienmitglieder, Freund:innen oder
                        Arbeitskolleg:innen, psychische Probleme oder Krisen bei
                        einer Person erkennen und hilfreich reagieren können.
                        Wenn Ängste oder Befürchtungen durch Knowhow ersetzt
                        werden, bleibt weniger Raum für Stigmatisierung und
                        Diskriminierung, worunter viele psychisch erkrankte
                        Menschen und ihre Familien noch heute zu leiden haben.
                        <br></br>
                        <br></br> Auch mit{" "}
                        <a href="/ersthelferkurse">
                            MHFA-Ersthelfer:innen-Kursen
                        </a>
                        , bieten wir die Möglichkeit, den eigenen
                        Handlungsspielraum zu erweitern und kompetent, wichtige
                        Hilfe bei psychischen Gesundheitsproblemen zu leisten.
                    </p>
                    <br></br>
                    <strong>Ü60</strong>
                    <br></br>
                    <br></br>
                    <p>
                        „Nicht dem Leben mehr Jahre, sondern den Jahren mehr
                        Leben geben.“ <br></br>
                        <br></br>Beratung und Therapie mit Menschen jenseits des
                        60. Lebensjahres gewinnt zunehmend an Bedeutung, auch in
                        meiner Praxis. Medizinischer Fortschritt und eine
                        zunehmend gesündere Lebensweise in der westlichen Welt
                        führen dazu, unserem Leben mehr Jahre zu geben. Es steht
                        außer Frage – wir werden immer älter! Umso mehr lohnt
                        sich der Blick in Richtung der Erhaltung von
                        Lebensqualität über die unterschiedlichen Phasen des
                        Alterns hinweg, bis ins hohe Lebensalter hinein.{" "}
                        <br></br>
                        <br></br>Wenn wir älter werden, stehen wir vor
                        zahlreichen neuen Entwicklungsaufgaben. Die zu
                        erwartende Lebenszeit wird kürzer, der Übergang vom
                        Berufsleben in den „Ruhestand“ will gestaltet werden.
                        Soziale Beziehungen innerhalb und außerhalb der Familien
                        verändern sich. Die Ablösung der Kinder muss bewältigt
                        werden. Gegebenenfalls müssen wir uns in die Rollen als
                        Großeltern und Schwiegereltern einfinden. Der Alltag
                        muss an neue Rollen, beispielsweise als pflegende
                        Partnerin oder Partner, angepasst werden.<br></br>
                        <br></br> Paare müssen lernen, mit der neuen Leere, aber
                        auch mit den (wieder)gewonnenen Freiheiten umzugehen.
                        Sie müssen sich neu finden und miteinander klären, was
                        sie sich für ihre Liebe, die gemeinsame Sexualität und
                        ihr Leben im Alter wünschen. Hierzu gehört es auch, sich
                        mit körperlichen Veränderungen und möglichen
                        Erkrankungen. Nicht zuletzt, gewinnen Themen wie
                        Abschiede, Verluste, Sterben und Tod an Bedeutung.
                    </p>

                    <br></br>
                    <h3>PREISGESTALTUNG</h3>
                    <p>
                        Das deutsche Gesundheitssystem erlaubt es nur
                        approbierten Ärzt:innen, psychologischen
                        Psychotherapeut:innen und Psycholog:innen mit einer
                        Zusatzausbildung für Kinder- und Jugendlichen
                        Psychotherapie, mit den Krankenkassen abzurechnen.{" "}
                        <br></br>
                        <br></br>Einzelberatung (60 Minuten) {">"} {einzelpreis}
                        ,- € <br></br>
                        <br></br>Familien- bzw. Gruppenberatung (90 Minuten){" "}
                        {">"}
                        {gruppenpreis},- € <br></br>
                        <br></br>Workshops und Kurse werden gesondert berechnet.
                        Sehen Sie hierzu mehr unter{" "}
                        <a href="/ersthelferkurse">MHFA</a> und{" "}
                        <a href="/ichschaffs">ich schaff`s</a>.
                    </p>
                    <button onClick={onClickTermin}>
                        Beratungsgespräch vereinbaren
                    </button>
                    <h3>Mehr zur Theorie</h3>

                    <strong>Lösungsfokussiert</strong>
                    <br></br>
                    <br></br>
                    <p>
                        Die LFT (lösungsfokussierte Therapie) wurde in den
                        frühen achtziger Jahren von Steve de Shazer, Insoo Kim
                        Berg und Kollegen am Brief Family Therapy Center
                        (Milwaukee, USA) entwickelt und fortlaufend modifiziert.
                        Der lösungsfokussierte Ansatz ist ein stark
                        ressourcenorientiertes Modell zur Gesprächsführung in
                        verschiedensten Anwendungsfeldern. LFT findet Anwendung
                        in Psychotherapie und Beratung (Einzel-, Paar-,
                        Familienberatung, Suchtberatung), Coaching, Supervision,
                        Mediation, Sozialarbeit (u.a. Jugendhilfe),
                        Elternarbeit, Krisenintervention, Psychiatrie,
                        Rehabilitationsberatung, Rauchentwöhnung,
                        Schulberatung/Schulsozialarbeit, Telefonseelsorge…
                        <br></br>
                        <br></br>Auch in Deutschland findet dieser Ansatz seit
                        ca. 15-20 Jahren immer mehr Verbreitung. Kennzeichen des
                        lösungsfokussierten Vorgehens sind intensive Ziel- und
                        Ressourcenorientierung, sowie Empowerment und die
                        pragmatische Suche nach den nächstmöglichen Schritten
                        hin zu einer Lösung, und seien die Schritte noch so
                        klein. Eine Grundannahme der LFT ist, dass eine
                        eingehende Beschäftigung mit Struktur und Ursachen von
                        Problemen, für das Finden von Lösungen nicht notwendig
                        ist. Vielmehr wird angenommen, dass es für das
                        Entwickeln von Lösungsschritten hilfreich ist, Klienten
                        dazu anzuregen, über Ausnahmen vom Problem in der
                        Vergangenheit zu sprechen und über Wünsche und Ziele für
                        die Zukunft – Solutiontalk! <br></br>
                        <br></br>
                        Zur wissenschaftlichen Forschung finden Sie unter
                        folgendem Link nähere Informationen:&nbsp;
                        <br></br>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.loesungsfokussiert.de/forschung"
                        >
                            https://www.loesungsfokussiert.de/forschung
                        </a>
                    </p>
                    <br></br>
                    <strong>Systemisch</strong>
                    <br></br>
                    <br></br>
                    <p>
                        In Zusammenhängen zu denken, und dabei komplexe
                        Wechselwirkungen zu entdecken und nutzbar zu machen, ist
                        nicht nur für naturwissenschaftliche Fachgebiete wie
                        beispielsweise die Physik oder die Biologie hilfreich
                        und sinnvoll. <br></br>
                        <br></br>Auch für die Beratungs- bzw. Therapiepraxis
                        spielen systemische Fragestellungen eine bedeutende
                        Rolle. Ausgehend von der Unterstützung von Familien,
                        breitete sich eine systemische Herangehensweise und
                        Grundhaltung in zahlreichen therapeutischen und
                        pädagogischen Konzepten und Methoden, sowie in der
                        Organisationsentwicklung stetig weiter aus.
                        <br></br>
                        <br></br> Der systemische Ansatz ist zu einer wichtigen
                        Säule bei der Gestaltung von Kommunikations- und
                        Partizipationsstrukturen innerhalb von
                        Unterstützungssystemen zur Förderung psychischer
                        Gesundheit geworden. Aber auch in unserer Gesellschaft
                        spielt systemisches Denken eine zunehmend wichtige Rolle
                        bei die Gestaltung eines konstruktiven Miteinanders.
                        <br></br>
                        <br></br>Wenn psychische Gesundheitsprobleme und Krisen,
                        Familien oder Teams belasten, kommt es darauf an,
                        handlungsfähig zu bleiben oder wieder handlungsfähig zu
                        werden. Therapeut:innen mit einer systemischen und
                        lösungsorientierten Grundhaltung, unterstützen ihre
                        Klient:innen, indem sie glaubwürdig offen für deren
                        Lebenseinstellungen und -konzepte sind, sich auf
                        individuelle Besonderheiten ihrer Klient: innen
                        einlassen. Systemische Berater:innen und Therapeut:innen
                        geben Perspektivenvielfalt Raum und inspirieren dazu,
                        neue konstruktive und kreative Wege zu gehen. Menschen
                        in vermeintlich ausweglosen Situationen gewinnen
                        systemischen Techniken das Gefühl und das Wissen zurück,
                        einen Einfluss zu haben und gestalten zu können.
                        <br></br>
                        <br></br>Zur wissenschaftlichen Forschung finden Sie
                        unter folgendem Link nähere Informationen: &nbsp;
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.loesungsfokussiert.de/forschung"
                        >
                            https://www.loesungsfokussiert.de/forschung
                        </a>
                    </p>
                </div>
            </div>
        </>
    );
}
