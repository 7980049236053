import "./LatestEvents.css";
import { client } from "./contentfulSetup";
import { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router";

export default function LatestEvents() {
    const history = useHistory();
    const [events, setEvents] = useState(null);

    const formatDate = (rawDate) => {
        let inputDate = rawDate;
        let date = new Date(inputDate);
        let formattedDate = date.toLocaleString("de-DE", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: false,
        });
        return formattedDate;
    };

    const cleanUpData = useCallback((rawData) => {
        const cleanEvents = rawData.map((singleEvent) => {
            const { sys, fields } = singleEvent;
            const { id } = sys;
            const { eventName, eventsDate, event } = fields;
            const imgUrl = event[0].fields.file.url;
            const formattedDate = formatDate(eventsDate);
            const cleanObj = {
                id,
                eventName,
                eventsDate,
                formattedDate,
                imgUrl,
            };

            return cleanObj;
        });
        if (cleanEvents.length) setEvents(cleanEvents);
    }, []);

    const getEvents = useCallback(async () => {
        try {
            const response = await client.getEntries({
                content_type: "events",
            });
            const responseData = response.items;

            if (responseData) {
                cleanUpData(responseData);
            } else {
                setEvents(null);
            }
        } catch (error) {
            console.log(error);
        }
    }, [cleanUpData]);

    useEffect(() => {
        getEvents();
    }, [getEvents]);

    return (
        <>
            {events && (
                <div className="latest-events-wrapper">
                    <div className="latest-events-text-wrapper">
                        <h3>Aktuell</h3>
                        <button onClick={() => history.push("/events")}>
                            Mehr Events anzeigen
                        </button>
                    </div>
                    <div className="latest-events-container">
                        {events.slice(0, 3).map((event, index) => {
                            return (
                                <div
                                    className="latest-event-wrapper"
                                    key={index}
                                >
                                    <div className="event-name-wrapper">
                                        <p>{event.eventName}</p>
                                        <p>{event.formattedDate}Uhr</p>
                                    </div>
                                    <img
                                        src={event.imgUrl}
                                        alt={event.eventName}
                                    ></img>
                                    <div className="date-event-wrapper">
                                        <button>Mehr dazu</button>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
}
