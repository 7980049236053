import "./HistoryHome.css";
import { client } from "./contentfulSetup";
import { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router";

export default function HistoryHome() {
    const history = useHistory();
    const [historyImage, setHistoryImage] = useState(null);
    const [historyText, setHistoryText] = useState(null);

    const getHistory = useCallback(async () => {
        try {
            const response = await client.getEntries({
                content_type: "history",
            });
            const responseData = response.items[0].fields;

            if (responseData) {
                setHistoryImage(responseData.historyImages[0].fields.file.url);
                setHistoryText(responseData.historyText);
            } else {
                setHistoryImage([]);
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getHistory();
    }, [getHistory]);

    return (
        <div className="history-wrapper">
            <div>
                <h3>Die Geschichte des Hauses</h3>
                <button
                    onClick={() => {
                        history.push("/klaranova");
                    }}
                >
                    Zum Haus
                </button>
            </div>
            <div className="history-text-image-wrapper">
                {historyText && <p>{historyText}</p>}
                <div>
                    <img
                        src="https://i.ibb.co/KDzQmwr/Bildschirmfoto-2023-03-07-um-18-15-10.jpg"
                        alt="altes-hausklaranova"
                    />
                    {historyImage && <img src={historyImage} alt="old house" />}
                </div>
            </div>
            <div className="breakline-history"></div>
            <div className="fusszeile-history-wrapper">
                <p>
                    1. Lehmann, Karl-August. Harmersbach. Die Geschichte eines
                    Tales. 1139-1812. Band I; Selbstverlag Karl- August Lehmann,
                    Oberharmersbach 1989<br></br> 2. Lehmann, Karl-August.
                    Harmersbach. Die Gemeinde Oberharmersbach. 1812-1991. Band
                    II; Selbstverlag Karl-August Lehmann, Oberharmersbach 1989
                    <br></br> 3. Aushang an der Kapelle zur Entstehung der
                    „Maria Hilf Kapelle“<br></br>4. Bild von zwei Frauen vor dem
                    Haus St Klara:
                    https://www.schwarzwaelder-post.de/orte-im-verbreitungsgebiet/oberharmersbach/2018/03/historische-augenblicke-59/32362
                </p>
            </div>
        </div>
    );
}
