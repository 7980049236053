import "./Kontakt.css";
import { useLoadScript } from "@react-google-maps/api";
import Map from "./Map";

export default function Kontakt() {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyAVEjR1r-uwXYH7TmP71sUjwBhFH9dww_Q",
    });

    if (!isLoaded) return <h1>Loading...</h1>;
    return (
        <>
            <Map />
            <div className="kontakt-wrapper">
                <div>
                    <h3>Kontakt</h3>
                    <p>Haus Klara Nova</p>
                    <p>Telefon: 07837/9679799</p>
                    <p>E-Mail: kontakt@klara-nova.de</p>
                </div>
                <div>
                    <h3>Anfahrt</h3>
                    <p>Zuwald 5</p>
                    <p>77784 Oberharmersbach</p>
                    <p>Deutschland</p>
                </div>
            </div>
        </>
    );
}
