import "./Chat.css";
import { useState, useEffect } from "react";
import TerminPopUp from "./TerminPopUp";

export default function Chat() {
    const [text, setText] = useState("...");
    const [fontSize, setFontSize] = useState();
    const [timerID, setTimerID] = useState();
    const [shown, setShown] = useState(false);
    const [margin, setMargin] = useState();

    useEffect(() => {
        setFontSize("30px");
        setMargin("15px");
    }, []);

    const onHover = () => {
        const timer = setTimeout(() => {
            if (window.innerWidth > 600) {
                setFontSize("20px");
                setMargin("0px");
                setText("Fragen Sie eine Chatberatung an");
            }
        }, 200);
        setTimerID(timer);
    };

    const onHoverOut = () => {
        clearTimeout(timerID);
        setText("...");
        setMargin("15px");
        setFontSize("30px");
    };

    const onClickX = () => {
        document.body.style.overflow = "scroll";
        setShown(!shown);
    };

    const onClickBubble = () => {
        document.body.style.overflow = "hidden";
        setShown(!shown);
    };

    const exitPage = () => {
        document.body.style.overflow = "scroll";

        setShown(!shown);
    };

    return (
        <>
            <TerminPopUp
                exitPage={exitPage}
                shown={shown}
                onClickX={onClickX}
            />
            <div
                onMouseEnter={onHover}
                onMouseLeave={onHoverOut}
                className="talk-bubble tri-right btm-right"
                onClick={onClickBubble}
            >
                <div className="talktext">
                    <p style={{ fontSize, marginBottom: margin }}>{text}</p>
                </div>
            </div>
        </>
    );
}
