import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import Home from "./home";
import UnderConstruction from "./UnderConstruction";
import Ersthelfer from "./Ersthelfer";
import Beratungspraxis from "./Beratungspraxis";
import Kontakt from "./Kontakt";
import Navbar from "./navbar";
import Bottom from "./Bottom";
import Ichschaffs from "./Ichschaffs";
import Uebermich from "./Uebermich";
import HausKlaraNova from "./HausKlaraNova";
import Chat from "./Chat";
import NavbarMobile from "./NavbarMobile";

export default function App() {
    return (
        <>
            <BrowserRouter>
                <Navbar />
                <NavbarMobile />
                <Chat />
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route exact path="/beratungspraxis">
                        <Beratungspraxis />
                    </Route>
                    <Route exact path="/ersthelferkurse">
                        <Ersthelfer />
                    </Route>
                    <Route exact path="/ichschaffs">
                        <Ichschaffs />
                    </Route>
                    <Route exact path="/uebermich">
                        <Uebermich />
                    </Route>
                    <Route exact path="/klaranova">
                        <HausKlaraNova />
                    </Route>
                    <Route exact path="/blog">
                        <UnderConstruction />
                    </Route>
                    <Route exact path="/kontakt">
                        <Kontakt />
                    </Route>
                </Switch>
                <Bottom />
            </BrowserRouter>
        </>
    );
}
