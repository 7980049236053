import "./Ichschaffs.css";
import MySvg from "./images/ichschaffs_logo.svg";
import TerminPopUp from "./TerminPopUp";
import { useState } from "react";

export default function Ichschaffs() {
    const [shown, setShown] = useState(false);

    const onClickTermin = (e) => {
        e.preventDefault();
        document.body.style.overflow = "hidden";
        setShown(true);
    };

    const onClickX = () => {
        document.body.style.overflow = "scroll";
        setShown(!shown);
    };

    return (
        <>
            <TerminPopUp shown={shown} onClickX={onClickX} />

            <div className="ichschaffs-wrapper">
                <div>
                    <div className="ichschaffs-ueberschrift-wrpper">
                        <h3>Der</h3>
                        <img src={MySvg} alt="ich schaffs" />
                        <h3>-Ansatz</h3>
                    </div>
                    <br></br>
                    <br></br>
                    <p>
                        Mit einfach nachvollziehbaren Schritten hilft „ich
                        schaff’s“ Kindern und Jugendlichen dabei, die
                        Fähigkeiten zu lernen, die sie brauchen, um ihre
                        Herausforderungen zu meistern.
                    </p>
                    <br></br>
                    <br></br>
                    <div className="ueberschriften-ichschaffs-wrapper">
                        <img src={MySvg} alt="test" />
                        <h4>ist, wenn Menschen …</h4>
                    </div>
                    <br></br>
                    <br></br>
                    <ul>
                        <li>
                            durch die Begegnung auf Augenhöhe den Aufbruch zu
                            neuen Abenteuern wagen
                        </li>
                        <li>mit Lust am Lernen neue Fähigkeiten entwickeln</li>
                        <li>
                            durch die Konzentration auf Stärken für ihre Ziele
                            ermächtigt werden
                        </li>
                        <li>
                            im gemeinsamen Tun Freude und Zuversicht erleben
                        </li>
                        <li>
                            mit Kraft und Leidenschaft Hindernisse überwinden
                        </li>
                        <li>
                            jeden Alters eine gemeinsame Sprache der
                            Lösungsorientierung sprechen
                        </li>
                        <li>
                            Menschen ihre großen und kleinen Erfolge gemeinsam
                            feiern und genießen
                        </li>
                    </ul>
                    <br></br>
                    <br></br>
                    <div className="ueberschriften-ichschaffs-wrapper">
                        <img src={MySvg} alt="test" />
                        <h4>für Kinder und Jugendliche</h4>
                    </div>
                    <br></br>
                    <p>
                        ich schaff's ist die Erkenntnis, dass Probleme von
                        Kindern und Jugendlichen als Fähigkeiten gesehen werden
                        können, die erlernbar oder verbesserbar sind. Eltern
                        werden darin gestärkt, ihre Kinder ressourcenorientiert
                        zu unterstützen.
                    </p>
                    <button
                        onClick={onClickTermin}
                        className="ichschaffs-button"
                    >
                        Beratungstermin vereinbaren
                    </button>
                    <div className="ueberschriften-ichschaffs-wrapper">
                        <img src={MySvg} alt="test" />
                        <h4>Elternkurse</h4>
                    </div>
                    <br></br>
                    <p>
                        Ich schaff’s für Eltern bietet nach dem bewährten
                        Konzept von ich schaff’s auch Eltern die Möglichkeit,
                        lösungsfokussierte Methoden der Erziehung in gezielten
                        Programmen zu lernen. Ich schaff’s für Eltern wird in
                        fünf gut strukturierten und überschaubaren Modulen in
                        Schulen, Kindergärten, Horten und Einrichtungen der
                        Jugendhilfe, der Kinder- und Jugendpsychiatrie
                        vermittelt.<br></br>
                        <br></br> In Kürze werden wir auch im Haus Klara Nova
                        frei buchbare Kurse für interessierte Eltern und weitere
                        Personen anbieten, die eng in die Erziehung von Kindern
                        eingebunden sind. Das trifft häufig auf Großeltern und
                        zu.<br></br>
                        <br></br> „Ich schaff´s-Bildungsurlaube“ für die ganze
                        Familie haben wir in Planung. Schauen Sie gerne wieder
                        vorbei.
                    </p>
                    <br></br>
                    <br></br>
                    <div className="ueberschriften-ichschaffs-wrapper">
                        <img src={MySvg} alt="test" />
                        <h4>für Eltern mit psychischen Gesundheitsproblemen</h4>
                    </div>
                    <br></br>
                    <br></br>
                    <p>
                        Auch für Eltern und weitere Erziehungspersonen, die
                        selbst Probleme mit ihrer psychischen Gesundheit haben
                        und Verantwortung für Kinder und Jugendliche übernehmen,
                        werden wir „Ich-schaff´s-Bildungsurlaube“ mit
                        kontextspezifischen Workshops anbieten.<br></br>
                        <br></br> Sie werden sich beispielsweise damit
                        auseinandersetzen, wie sie Ihren Kindern altersgerecht
                        helfen, Ihre psychischen Belastungen zu verstehen und
                        wie Sie ihnen Sicherheit geben können. <br></br>Sie
                        werden erfahren, wie Sie ihre Kinder vor Überforderung
                        schützen und in einer guten Beziehung bleiben können.{" "}
                        <br></br>
                        <br></br>Wir werden uns damit beschäftigen, wie Sie mit
                        Einflüssen aus Ihrem persönlichen Umfeld und der
                        Gesellschaft, die beileibe nicht immer vorurteilsfrei
                        oder wohlwollend sind, so umgehen können, dass Sie sich
                        selbst und Ihre Kinder schützen und stärken.<br></br>
                        <br></br> Nicht zuletzt wird es auch darum gehen, wie es
                        Ihnen gelingen kann, Netzwerke zu knüpfen, von denen
                        Ihre Kinder im Falle einer Krise aufgefangen werden, so
                        dass diese nicht aus allen vertrauten Bezügen
                        herausgerissen werden, wenn es Ihnen einmal schlechter
                        geht, und Sie sich gerade nicht gut selbst um Ihre
                        Familien kümmern können.<br></br>
                        <br></br>
                        <span style={{ color: "green" }}>
                            Alle ich schaff‘s-Eltern-Kurse ermöglichen ein
                            Von-einander-lernen auf Augenhöhe. Sie werden Ihren
                            Handlungsspielraum erweitern und gestärkt sein für
                            den oft herausfordernden Alltag.
                        </span>
                    </p>
                    <button
                        onClick={onClickTermin}
                        className="ichschaffs-button"
                    >
                        Termine und Anfragen
                    </button>
                </div>
            </div>
        </>
    );
}
