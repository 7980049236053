import "./TerminPopUp.css";
import { useHistory } from "react-router-dom";

export default function TerminPopUp({ shown, onClickX, exitPage }) {
    const history = useHistory();
    const email = "kontakt@klara-nova.de";
    const subject = "Anfrage an Heike Petereit-Zipfel";
    const body =
        "Guten Tag Frau Petereit-Zipfel, ich kontaktiere sie aus folgendem Grund...";
    const phoneNumber = "+497837/9679799";

    const handleEmailClick = () => {
        window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
    };

    const handlePhoneClick = () => {
        if (window.innerWidth < 768) {
            window.location.href = `tel:${phoneNumber}`;
        } else {
            exitPage();
            history.push("/kontakt");
        }
    };
    return (
        <>
            {shown && (
                <div className="TerminPopUp-Wrapper">
                    <div className="TerminPopUp">
                        <div>
                            <p onClick={onClickX}>X</p>
                        </div>
                        <div>
                            <h3>NEHMEN SIE JETZT KONTAKT AUF</h3>
                            <p>
                                Haben sie Fragen bezüglich einer unserer
                                Angebote? Möchten Sie eine Buchung machen? Oder
                                wollen sie Feedback geben?<br></br> <br></br>
                                Setzen Sie sich jetzt über einen unserer drei
                                Kanäle in Verbindung mit uns! <br></br>Wir
                                freuen uns auf Sie.
                            </p>
                        </div>
                        <div></div>
                        <div>
                            {/* <button className="button1-termin">Whatsapp</button> */}
                            <button
                                className="button2-termin"
                                onClick={handleEmailClick}
                            >
                                E-Mail
                            </button>
                            <button
                                className="button3-termin"
                                onClick={handlePhoneClick}
                            >
                                Telefon
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
