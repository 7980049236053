import ImageCarousel from "./ImageCarousel";
import LatestEvents from "./LatestEvents";
import PraxisHome from "./PraxisHome";
import HistoryHome from "./HistoryHome";
import BookNowBanner from "./BookNowBanner";
import LatestCourses from "./LatestCourses";

export default function Home() {
    return (
        <>
            <ImageCarousel />
            <PraxisHome />
            <LatestCourses showTop={true} />
            <BookNowBanner />
            <LatestEvents />
            <HistoryHome />
        </>
    );
}
