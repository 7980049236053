import "./Uebermich.css";
import { useCallback, useState, useEffect } from "react";
import { client } from "./contentfulSetup";

export default function Uebermich() {
    const [image, setImage] = useState(null);

    const getUeberMich = useCallback(async () => {
        try {
            const response = await client.getEntries({
                content_type: "ueberMich",
            });
            const responseData =
                response.items[0].fields.ueberMichImage.fields.file;
            if (responseData) {
                setImage(responseData.url);
            } else {
                setImage(responseData);
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getUeberMich();
    }, [getUeberMich]);

    return (
        <div className="uebermich-wrapper">
            {image && (
                <>
                    <div className="image-text-uebermich-wrapper">
                        <img src={image} alt="Bild von Heike Petereit-Zipfel" />
                        <p>
                            Funktioniert etwas, mach mehr davon; funktioniert
                            etwas trotz ernsthafter Bemühungen nicht, probiere
                            etwas anderes aus! So in etwa äußerte sich Steve de
                            Shazer, einer der Begründer der lösungsfokussierten
                            Therapie. Als ich dies zum ersten Mal las, fühlte es
                            sich an, wie eine sehr knappe Beschreibung meiner
                            eigenen Lebensphilosophie.<br></br>
                            <br></br> Mit einer solchen Haltung ist es nicht
                            möglich, dauerhaft im Negativen zu verharren. Sie
                            zwingt uns in einen Prozess der Versöhnung mit
                            eigenen Grenzen, und trainiert unser Wohlwollen und
                            unsere Gelassenheit, aber auch unsere Konsequenz im
                            Umgang mit anderen, ob in der Familie, unter
                            Freund:innen und Bekannten oder Arbeitskolleg:innen.
                            <br></br>
                            <br></br> Gemeinsam öffnen wir Ausblicke und
                            Einblicke. Die Lösungen für Ihre Herausforderungen
                            und Probleme, werden Sie hierbei selbst
                            konstruieren. Diese werden zu Ihrem Leben und Ihren
                            Bedürfnissen passen und Ihren Handlungsspielraum
                            dauerhaft erweitern.
                        </p>
                    </div>
                    <div className="text-uebermich-wrapper">
                        <h3>KURZVITA</h3>
                        <p>
                            Ich werde älter, bin verheiratet und habe vier
                            erwachsene Kinder.<br></br>
                            <br></br> Schon früh interessierte ich mich für
                            Psychologie und Soziologie. Mein beruflicher
                            Werdegang führte allerdings erst über Umwege zu
                            meiner Berufung, der Arbeit mit und für Menschen in
                            schwierigen Situationen. <br></br>
                            <br></br>Zunächst arbeitete ich als
                            Fremdsprachenkorrespondentin, später als
                            Journalistin und Redaktionsassistentin bei der
                            Tageszeitung „Die Rheinpfalz“, und schließlich beim
                            Deutschen Bundestag als Leiterin des Regionalbüros
                            eines Bundestagsabgeordneten in Ingolstadt, Bayern.
                            Mein Umzug nach Baden-Württemberg im Jahr 2004 gab
                            den Anstoß, mich beruflich in eine andere Richtung
                            weiterzuentwickeln. Inzwischen bin ich
                            Sozialarbeiterin/Sozialpädagogin (KH Freiburg),
                            Psychosozialtherapeutin,{" "}
                            <a
                                target="_blank"
                                href="https://www.loesungsfokussiert.de/"
                                rel="noreferrer"
                            >
                                Lösungsfokussierte Beraterin
                            </a>
                            , psychotherapeutische Heilpraktikerin,&nbsp;
                            <a
                                href="https://ichschaffs.de/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Familiencoach
                            </a>{" "}
                            und last but not least Instruktorin für&nbsp;
                            <a
                                href="https://www.zi-mannheim.de/behandlung/mhfa-mental-health-first-aid.html"
                                target="_blank"
                                rel="noreferrer"
                            >
                                MHFA Ersthelfer Kurse
                            </a>{" "}
                            für mentale Gesundheit.
                            <br></br>
                            <br></br>
                            Seit elf Jahren betreibe ich eine psychosoziale
                            Beratungspraxis. Professionelle Weiterbildung ist
                            meine ständige Begleiterin. Und auch bei meinen
                            ehrenamtlichen Aktivitäten in der organisierten
                            Interessenvertretung der Angehörigen von Menschen
                            mit gravierenden psychischen Gesundheitsproblemen,
                            lerne ich seit zehn Jahren viel. Meine wertvollsten
                            Lehrmeister:innen sind jedoch meine Klient:innen.
                            Dafür bin ich sehr dankbar.
                        </p>
                        <h3>GESELLSCHAFTLICHES ENGAGEMENT</h3>
                        <p>
                            Wenn ich möchte, dass Sie etwas über mich erfahren,
                            darf mein gesellschaftliches Engagement nicht
                            fehlen.<br></br>
                            <br></br> Gesellschaftspolitische Themen und
                            Entwicklungen gehen uns alle an. Wir können uns
                            jedoch nicht um alles kümmern. Ich persönlich
                            entschied vor vielen Jahren, mich sozialpolitisch zu
                            engagieren. Es führte zu weit, hier auf die
                            unterschiedlichen Themen und Aktivtäten einzugehen.
                            Mein langjähriges und anhaltendes Engagement in der
                            oben bereits erwähnten organisierten
                            Interessenvertretung der Angehörigen und
                            Nahestehenden psychisch belasteter Menschen (ApK),
                            stelle ich Ihnen jedoch gerne näher vor.<br></br>
                            <br></br> Im Wesentlichen lässt sich dieses
                            Engagement in drei Bereiche einteilen, die Arbeit
                            von Angehörigen, die Arbeit mit Angehörigen und die
                            Arbeit für Angehörige.
                            <br></br>
                            <br></br>
                            <br></br>
                            Die Drei Dimensionen der Angehörigenarbeit:
                        </p>
                        <img
                            className="drei-dimensionen"
                            src="https://i.ibb.co/GVhhbZV/dreidimensions.png"
                            alt="drei-dimensionen-der-angehörigenarbeit"
                        />
                        <p className="eigene-grafik">
                            Eigene Grafik: Heike Petereit-Zipfel, PID 2021
                        </p>
                        <br></br>
                        <p>
                            <strong>Die Arbeit von Angehörigen</strong>
                            <br></br>
                            <br></br> Darunter lässt sich das Engagement von
                            Angehörigen in Gesellschaft und Politik
                            zusammenfassen, das wesentlich darauf abzielt, die
                            Lebens- und Behandlungsbedingungen für psychisch
                            erkrankte Familienmitglieder zu verbessern, den
                            eigenen Einfluss hierauf zu erhöhen und strukturelle
                            Nachteile für ApK als Mitbetroffene auszugleichen
                            oder zu verhindern.
                            <br></br>
                            <br></br>
                            <strong>Die Arbeit mit Angehörigen</strong>
                            <br></br>
                            <br></br>
                            Hierunter ist die Zusammenarbeit von ApK und
                            Fachpersonen, auf dem Feld der psychiatrischen und
                            psychosozialen Hilfen zu verstehen. Gemeinsam soll
                            die Person unterstützt werden, die mit psychischen
                            Gesundheitsproblemen zu kämpfen hat. Die Aktivitäten
                            finden sowohl im stationären als auch im ambulanten
                            Setting statt, und beziehen die psychisch belasteten
                            Personen mit ein. Dieser „Trialog“ wird in der
                            aktuellen Ausgabe der S3 Leitlinie „Psychosoziale
                            Therapien bei schweren psychischen Erkrankungen“ für
                            die Fortentwicklung der Versorgungsstrukturen und
                            zur Qualitätsförderung bereits empfohlen. <br></br>
                            <br></br>
                            <strong>Die Arbeit für Angehörige</strong>
                            <br></br>
                            <br></br> Diese Aktivitäten fokussieren darauf,
                            Angehörige als hoch belasteten Personenkreis zu
                            unterstützen und zu stärken. Sie finden bisher nur
                            sehr eingeschränkt statt. Ergänzend zu
                            Selbsthilfegruppen, gibt es auch angeleitete
                            Angehörigengesprächskreise, beispielsweise in
                            Kliniken. Auch Mehrpersonengespräche im stationären
                            Behandlungsverlauf sind möglich. Allerdings sind in
                            vielen Kliniken proaktive Gesprächsangebote auch
                            heute noch nicht selbstverständlich. <br></br>
                            <br></br>Als Arbeit für Angehörige können auch
                            Psychotherapien bewertet werden. Hier spielt die
                            Familientherapie (FT) eine wichtige Rolle. Als
                            Chance zur Veränderung dysfunktionaler familiärer
                            Kommunikationsmuster, ist sie grundsätzlich dazu
                            geeignet, ApK zu entlasten. <br></br>
                            <br></br> Ich persönlich engagiere mich in all
                            diesen Bereichen intensiv. Auf lokaler Ebene
                            beispielsweise als ehrenamtliche Beraterin in
                            einer&nbsp;
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://sozialministerium.baden-wuerttemberg.de/de/gesundheit-pflege/medizinische-versorgung/psychiatrische-versorgung/unabhaengige-anlaufstellen"
                            >
                                IBB-Stelle.
                            </a>
                            <br></br>
                            <br></br>
                            Auf Landesebene bin ich mitverantwortlich für das
                            von der Aktion Mensch und der Stiftung Stütze
                            geförderte Projekt{" "}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="http://ipags.de/"
                            >
                                „IPAGs“
                            </a>
                            , mit dem die Interessenvertretung von Angehörigen
                            und Psychiatrie-Erfahrenen in den
                            Gemeindepsychiatrischen Verbünden in BW gestärkt
                            werden soll. <br></br>
                            <br></br> Auf Bundesebene, als stellvertretende
                            Vorsitzende des Bundesverbands der Angehörigen
                            psychisch erkrankter Menschen (
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.bapk.de/der-bapk.html"
                            >
                                BApK
                            </a>
                            ), arbeite ich in zahlreichen
                            Leitlinienkommissionen, Fachgremien und Beiräten
                            mit.<br></br>
                            <br></br> Angehörige, die sich auf irgendeine Weise
                            in der Selbsthilfe engagieren, und sei es „nur“ die
                            Teilnahme an Selbsthilfegruppen, bewältigen ihre
                            Lebenssituation konstruktiver und fühlen sich
                            weniger belastet. Das zeigt auch eine
                            wissenschaftliche Forschungsarbeit, die ich selbst
                            im vergangenen Jahr 2022 durchführte. Die
                            Gesamtergebnisse werde ich hier bald zugänglich
                            machen. <br></br>
                            <br></br>
                            <span className="thankssaying">
                                An dieser Stelle bedanke ich mich noch einmal
                                sehr herzlich bei den vielen Menschen, die sich
                                an meiner Umfrage für diese Arbeit beteiligten!
                            </span>
                            <br></br>
                            <br></br>
                            Auf der Homepage des BApK finden Sie Verlinkungen zu
                            den jeweiligen Landesverbänden. Hier gibt Sie in der
                            Regel eine Übersicht über die Selbsthilfegruppen in
                            den einzelnen Bundesländern. Hier können Sie sich
                            auch darüber informieren, wie Sie sich ansonsten in
                            der Bewegung engagieren können. Wenden Sie sich
                            diesbezüglich gerne auch an mich persönlich! E-Mail:
                            heike.petereit-zipfel@bapk.de <br></br>
                            <br></br>Der BApK bietet auch eine eigene
                            qualifizierte Telefonberatung an. Das{" "}
                            <span className="seelfon">„Seelefon“&nbsp;</span>
                            erreichen Sie über die Telefonnummer{" "}
                            <span className="seelfon">0228-71002424</span>.
                            <br></br>
                            <br></br> Weitere Informationen über das{" "}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.bapk.de/angebote/seelefon.html"
                            >
                                „Seelefon“
                            </a>
                            &nbsp; und die Aktivitäten des BApK, finden sie
                            ebenfalls auf unserer Homepage.
                        </p>
                    </div>
                </>
            )}
        </div>
    );
}
