import "./UnderConstruction.css";

export default function UnderConstruction() {
    return (
        <>
            <div className="underconstruction-wrapper">
                <img
                    src="https://img.icons8.com/ios/512/road-worker.png"
                    alt="underconstruction"
                />
                <div>
                    <h1>Diese Seite ist noch im Aufbau</h1>
                    <p>
                        Hier entsteht gerade der Blog für das Haus Klara Nova,
                        dem Ort für Erholung, Bildung und Kultur <br></br>
                        <br></br>
                        und für die Psychosoziale Beratungspraxis von Heike
                        Petereit-Zipfel. Wir werden bald für Sie da sein.
                    </p>
                    <p className="mama">Heike Petereit-Zipfel</p>
                    <ul>
                        <li>Sozialarbeiterin / Sozialpädagogin</li>
                        <li>Psychosozialtherapeutin</li>
                        <li>Heilpraktikerin für Psychotherapie</li>
                        <li>Familiencoach systemisch</li>
                        <li>
                            Instruktorin MHFA-Ersthelfer # Kurse für seelische
                            Gesundheit
                        </li>
                        <li>Stellvertretende Vorsitzende BApK</li>
                        <p>mailto: kontakt@klara-nova.de</p>
                        <p>Zuwald 5 • 77784 Oberharmersbach</p>
                        <p>fon: 07837-9679799</p>
                        <p>mobil: 0160-97532768</p>
                        <p>https://www.klara-nova.de</p>
                    </ul>
                </div>
            </div>
        </>
    );
}
