import { useState, useEffect, useCallback } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./ImageCarousel.css";
import { client } from "./contentfulSetup";

export default function ImageCarousel() {
    const [current, setCurrent] = useState(0);
    const [carouselImages, setCarouselImages] = useState([]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (current === carouselImages.length - 1) setCurrent(0);
            else setCurrent(current + 1);
        }, 5000);

        return () => clearInterval(interval);
    }, [current, carouselImages]);

    const cleanUpData = useCallback((rawData) => {
        const cleanCarousel = rawData.map((image) => {
            const { sys, fields } = image;
            const { id } = sys;
            const imgUrl = `https:` + fields.file.url;
            const cleanObj = { id, imgUrl };
            return cleanObj;
        });
        setCarouselImages(cleanCarousel);
    }, []);

    const getCarouselImages = useCallback(async () => {
        try {
            const response = await client.getEntries({
                content_type: "homePage",
            });

            const responseData = response.items[0].fields.imageCarousel;
            if (responseData) {
                cleanUpData(responseData);
            } else {
                setCarouselImages([]);
            }
        } catch (error) {
            console.log(error);
        }
    }, [cleanUpData]);

    useEffect(() => {
        getCarouselImages();
    }, [getCarouselImages]);

    return (
        <>
            {carouselImages && (
                <Carousel
                    infiniteLoop={true}
                    autoPlay={true}
                    interval={6000}
                    showThumbs={false}
                    showArrows={false}
                    showStatus={false}
                    showIndicators={true}
                    swipeable={false}
                >
                    {carouselImages.map((image, index) => {
                        return (
                            <div key={index}>
                                <img
                                    className="carousel-images"
                                    src={image.imgUrl}
                                    alt={index}
                                />
                            </div>
                        );
                    })}
                </Carousel>
            )}
            <div className="welcome-text">
                <p>Willkommen</p>
                <p>bei Klara Nova</p>
                <p>dem Ort für Erholung und Perspektivwechsel</p>
            </div>
        </>
    );
}
