import { GoogleMap, Marker } from "@react-google-maps/api";
import "./Map.css";

const center = { lat: 48.40294952370604, lng: 8.16386894783462 };

export default function Map() {
    return (
        <GoogleMap
            zoom={16}
            center={center}
            mapContainerClassName="map-container"
            mapTypeId="satellite"
        >
            <Marker position={center} />
        </GoogleMap>
    );
}
