import "./NavbarMobile.css";
import HamburgerMenu from "./HamburgerMenu";
import { useEffect, useState, useCallback } from "react";
import { client } from "./contentfulSetup";

export default function NavbarMobile() {
    const [logo, setLogo] = useState([]);

    const cleanUpData = useCallback((rawData) => {
        const cleanLogo = rawData.map((image) => {
            const { sys, fields } = image;
            const id = sys.id;
            const imgUrl = fields.logoImage.fields.file.url;
            const cleanObjImg = { id, imgUrl };
            return cleanObjImg;
        });

        console.log("cleanLogo", cleanLogo[0]);
        setLogo(cleanLogo[0]);
    }, []);

    const getLogoImage = useCallback(async () => {
        try {
            const response = await client.getEntries({
                content_type: "logo",
            });

            const responseData = response.items;
            if (responseData) {
                cleanUpData(responseData);
            } else {
                setLogo([]);
            }
        } catch (error) {
            console.log(error);
        }
    }, [cleanUpData]);

    useEffect(() => {
        getLogoImage();
    }, [getLogoImage]);

    return (
        <div className="navbar-wrapper-mobile">
            <a href="/">
                {logo && (
                    <img
                        className="logo-image-mobile"
                        src={logo.imgUrl}
                        alt="logo"
                    />
                )}
            </a>
            <HamburgerMenu />
        </div>
    );
}
