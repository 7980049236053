import "./HausKlaraNova.css";
import { useCallback, useState, useEffect } from "react";
import { client } from "./contentfulSetup";

export default function HausKlaraNova() {
    const [image, setImage] = useState(null);

    const getHausKlaraNova = useCallback(async () => {
        try {
            const response = await client.getEntries({
                content_type: "hausKlaraNova",
            });
            const responseData =
                response.items[0].fields.klaraNovaImage.fields.file;
            if (responseData) {
                setImage(responseData.url);
            } else {
                setImage(responseData);
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getHausKlaraNova();
    }, [getHausKlaraNova]);

    return (
        <div className="huasklaranova-wrapper">
            <img src={image} alt="Bild Haus Klara Nova" />
            <div className="klaranova-bottom-wrapper">
                <p>
                    Das beschauliche Anwesen „Klara Nova“, früher „St. Klara“,
                    liegt in einem ruhigen Seitental des Harmersbachtals im
                    nördlichen Schwarzwald.<br></br>
                    <br></br> Noch sind wir am Renovieren, aber schon bald
                    können Sie bei uns Ihre Seele baumeln lassen. <br></br>
                    <br></br>
                    Erleben Sie eine Auszeit voller Gelassenheit, umgeben von
                    uriger Natur, und genießen Sie kulinarische Köstlichkeiten
                    der Region.<br></br>
                    <br></br> Bei Klara Nova können Sie kreativ werden,
                    kulturelle Angebote genießen, sich mit einem Bildungsurlaub
                    stärken oder auch einfach einmal nichts tun.<br></br>
                    <br></br> Schauen Sie gerne wieder vorbei, erfahren Sie wann
                    es losgeht und weitere Details!
                </p>
                <div>
                    <ul>
                        <h4>Kleiner Steckbrief:</h4>
                        <li>Gruppen bis zu 20 Personen</li>
                        <li>
                            Großzügige Gemeinschaftsräume mit Küche und
                            Sanitärräumen im EG
                        </li>
                        <li>
                            neun Schlafzimmer, drei Bäder, vier Toiletten im 2.
                            OG
                        </li>
                        <li>
                            barrierearm mit Fahrstuhl, hauseigener Rollstuhl
                            vorhanden
                        </li>
                        <li>Überdachter Aufenthalt draußen</li>
                        <li>Sonnenliegen</li>
                        <li>Grillbereich</li>
                        <li>Kleine Fahrradwerkstatt</li>
                    </ul>
                </div>
            </div>
            <div className="construction-klaranova-wrapper"></div>
        </div>
    );
}
